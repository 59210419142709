<template>
  <div class="flex gameIntroduction">
    <el-container>
      <el-container>
        <el-aside>
          <div class="flex flex-between items-center">
            <div class="gameIntroduction-title">游戏介绍</div>
            <i
              class="el-icon-arrow-down"
              style="font-size: 16px; color: #b1b1b1"
            ></i>
          </div>
          <div class="gameIntroduction-type flex">
            <div class="gameIntroduction-border">
              <div
                class="gameIntroduction-border-typeindex"
                :style="{
                  transform: 'translatey(' + typeIndex * 56 + 'px)',
                  top: 0 + 'px',
                }"
              ></div>
            </div>
            <div class="gameIntroduction-type-ul">
              <div
                v-for="(item, index) in typeList"
                :key="index"
                class="gameIntroduction-type-ul-li"
                :class="
                  typeIndex == index ? 'gameIntroduction-type-ul-border' : ''
                "
                @click="typeIndex = index"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </el-aside>
        <el-container>
          <el-main>
            <div class="gameIntroduction-butom">
              <div class="gameIntroduction-content">
                <div class="gameIntroduction-content-title">
                  {{ typeList[typeIndex].name }}
                </div>
              </div>
              <div>
                <el-image
                  style="width: 100%"
                  :src="typeList[typeIndex].img"
                  fit="fill"
                ></el-image>
              </div>
              <div
                class="gameIntroduction-buttom flex flex-between items-center"
                v-if="typeIndex == 0"
                @click="typeIndex = typeIndex + 1"
              >
                <div class="gameIntroduction-buttom-one">
                  <div class="gameIntroduction-buttom-one-next">Next</div>
                  <div class="gameIntroduction-buttom-one-name">
                    {{ typeList[typeIndex + 1].name }}
                  </div>
                </div>
                <i
                  class="el-icon-arrow-right"
                  style="font-size: 16px; color: #b1b1b1"
                ></i>
              </div>
              <div
                v-else-if="typeIndex + 1 == typeList.length"
                class="gameIntroduction-buttom flex flex-between items-center border32"
                @click="typeIndex = typeIndex - 1"
              >
                <i
                  class="el-icon-arrow-left"
                  style="font-size: 16px; color: #b1b1b1"
                ></i>
                <div class="gameIntroduction-buttom-one">
                  <div class="gameIntroduction-buttom-one-next">Next</div>
                  <div class="gameIntroduction-buttom-one-name">
                    {{ typeList[typeIndex - 1].name }}
                  </div>
                </div>
              </div>
              <div v-else class="flex gameIntroduction-centre flex-between">
                <div
                  class="gameIntroduction-centre-button flex flex-between items-center border32"
                  @click="typeIndex = typeIndex - 1"
                >
                  <i
                    class="el-icon-arrow-left"
                    style="font-size: 16px; color: #b1b1b1"
                  ></i>
                  <div class="gameIntroduction-centre-button-one">
                    <div class="gameIntroduction-centre-button-one-next">
                      Next
                    </div>
                    <div class="gameIntroduction-centre-button-one-name">
                      {{ typeList[typeIndex - 1].name }}
                    </div>
                  </div>
                </div>
                <div
                  class="gameIntroduction-centre-button flex flex-between items-center"
                  @click="typeIndex = typeIndex + 1"
                >
                  <div class="gameIntroduction-centre-button-one">
                    <div class="gameIntroduction-centre-button-one-next">
                      Next
                    </div>
                    <div class="gameIntroduction-centre-button-one-name">
                      {{ typeList[typeIndex + 1].name }}
                    </div>
                  </div>
                  <i
                    class="el-icon-arrow-right"
                    style="font-size: 16px; color: #b1b1b1"
                  ></i>
                </div>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeIndex: 0,
      typeList: [
        {
          name: "模式介绍",
          img: "https://cdn.bubbleplan.cn/static/textImg/Modeintroduction.png",
        },
        {
          name: "角色介绍",
          img: "https://cdn.bubbleplan.cn/static/textImg/roleintroduction.png",
        },
        {
          name: "玩法介绍",
          img: "https://cdn.bubbleplan.cn/static/textImg/Gameplayintroduction.png",
        },
        // {
        //   name: "道具介绍",
        // },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(.el-main::-webkit-scrollbar) {
  width: 0px;
  height: 0px;
}
.gameIntroduction {
  padding-left: 221px;
  padding-top: 70px;
  background: #fff;
  height: calc(100vh - 94px);
  :deep(.el-aside) {
    width: 254px !important;
  }
  &-title {
    color: #5d5d5d;
    font-size: 20px;
    font-weight: 700;
  }
  &-border {
    width: 1px;
    background: #dbdbdb;
    position: relative;
    // &-typeindex {
    //   width: 1px;
    //   height: 26px;
    //   background: #346ddb;
    //   position: absolute;
    //   left: 0;
    //   transition-duration: 300ms;
    // }
  }
  &-type {
    margin-top: 30px;
    &-ul {
      width: 100%;
      &-li:hover {
        cursor: pointer;
        background: #f6f6f6;
        border-radius: 8px;
      }
      &-li {
        width: 100%;
        height: 41px;
        line-height: 41px;
        color: #5d5d5d;
        font-size: 18px;
        margin-bottom: 11px;
        padding: 0 31px;
      }
      &-border {
        background: #e6edfb !important;
        color: #346ddb !important;
      }
    }
  }
  &-content {
    margin-bottom: 20px;
    &-title {
      color: #000000;
      font-size: 34px;
      font-weight: 700;
    }
  }
  &-buttom:hover {
    cursor: pointer;
  }
  .border32 {
    padding-left: 32px;
  }
  &-centre {
    margin-top: 60px;

    &-button:hover {
      cursor: pointer;
    }
    &-button {
      width: 462px;
      height: 89px;
      border-radius: 6px;
      border: 1px solid #e4e4e4;
      padding: 0 32px 0 22px;

      &-one {
        &-next {
          color: #595c59;
          font-size: 16px;
        }
        &-name {
          margin-top: 8px;
          color: #1f1f1f;
          font-size: 19px;
        }
      }
    }
  }
  &-buttom {
    width: 100%;
    height: 89px;
    border-radius: 6px;
    border: 1px solid #e4e4e4;
    margin-top: 60px;
    padding: 0 32px 0 22px;
    &-one {
      &-next {
        color: #595c59;
        font-size: 16px;
      }
      &-name {
        margin-top: 8px;
        color: #1f1f1f;
        font-size: 19px;
      }
    }
  }
  &-butom {
    width: 938px;
    margin-left: 110px;
  }
}
</style>